import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import BuildIcon from '@mui/icons-material/Build'
import { Button } from '@mui/material'
import fetchError from '../lib/global/error_api'
import { isBrowser } from '../utils/utils'
import Helmet from 'react-helmet'

const DebugPage = (props: any) => {
  const [valid, setValid] = React.useState(true)
  const [ticket, setTicket] = React.useState(undefined)

  const seo = (
    <Helmet>
      <meta content="noindex, follow" name="robots" />
    </Helmet>
  )

  const keys = []

  if (isBrowser()) {
    keys.push(process.env.STORAGE_PREFIX + '_' + 'cart')
    keys.push(process.env.STORAGE_PREFIX + '_' + 'cartitems')
    keys.push(process.env.STORAGE_PREFIX + '_' + 'favorites')
    keys.push(process.env.STORAGE_PREFIX + '_' + 'eversion')
  }

  const display: any = []

  let plainData = ''

  const base64: string[] = []

  keys.forEach((itemName: string) => {
    const content = '' + localStorage.getItem(itemName)

    display.push({
      title: itemName,
      item_name: itemName,
      content_json: content,
    })

    base64.push(itemName + ' ' + new Buffer(content).toString('base64'))

    plainData = plainData + itemName + ':' + content + ' '
  })

  const isLoading = display.length == 0

  const handleClick = () => {
    setValid(false)
    fetchError({ data: plainData }).then((d: any) => {
      setTicket(d.data.id)
    })

    base64.forEach((base64str) => {
      fetchError({ data: base64str })
    })
  }

  let storageSize = 0

  if (isBrowser()) {
    for (const key in localStorage) {
      const value = localStorage.getItem(key)

      if (value !== null) {
        storageSize += key.length + value.length
      }
    }
  }

  storageSize = Math.round((storageSize / 1024) * 100) / 100

  if (isLoading) {
    return (
      <>
        {seo}
        <div className="container">
          <div className="row">
            <div className="col-md">
              <h1>Wir benötigen deine Unterstützung</h1>
              <LinearProgress />
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        {seo}
        <div className="container">
          <div className="row">
            <div className="col-md">
              <h1>Wir benötigen deine Unterstützung</h1>
              <p>
                Sehr wahrscheinlich wurdest du vom Kundenservice auf diese Seite
                geleitet, damit du uns Informationen zur Fehlerbehebung
                bereitstellen kannst. Beim Drücken des folgenden Knopfes, werden
                die angezeigten Inhalte direkt in unsere IT übermittelt. Du
                bekommst eine Nummer angezeigt, sende bitte diese Nummer an den
                Kundensupport als Mailantwort zurück. Danke!
              </p>
              <p>Der lokal belegte Speicher beträgt {storageSize} KB</p>
              <p>
                {ticket !== undefined && (
                  <p>
                    Vielen Dank. Bitte sende uns die Debug-Nummer{' '}
                    <b>{ticket}</b> per Mail zu!
                  </p>
                )}
                {ticket === undefined && (
                  <Button
                    color="primary"
                    disabled={!valid}
                    onClick={handleClick}
                    variant="contained"
                  >
                    <BuildIcon /> Debug-Informationen senden
                  </Button>
                )}
              </p>
              {display.map((elem: any) => (
                <div key={elem.item_name + '_div'}>
                  <h2>{elem.title}</h2>
                  <p key={elem.item_name + '_p'}>
                    {elem.item_name} {elem.content_json}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        </>
    )
  }
}

export default DebugPage
